import React, { useEffect } from 'react'
import { Box, Button, Card, MenuItem } from '@mui/material'
import Input from 'components/Input'
import FileUpload from 'components/FileUpload'
import { imageExt } from 'config/extensions'
import type { Market } from 'types/Market'
import Select from 'components/Select'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from 'context/AuthContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosPatch } from 'connectors/axiosPatch'
import { useParams, useSearchParams } from 'react-router-dom'
import useQueryGet from 'hooks/useQueryGet'
import type { Promotion } from '../Promotions.types'
import { useAlert } from 'context/AlertContext'
import { useHandleForm } from 'hooks/useHandleForm'
import type { UseHandleFormReturnType } from 'hooks/useHandleForm/useHandleForm'
import { format } from 'date-fns'
import { readQueryParams } from 'utils/generateQueryParams'

export interface EditPromotionProps {
  market?: Market
  selectedMarketId: string
}

const EditPromotion = ({ selectedMarketId, market }: EditPromotionProps): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setValue, control, Provider, onHandleSubmit, watch } = useEditPromotion(params?.id, selectedMarketId)

  const { data: promotionData } = useQueryGet<Promotion>({ endpoint: `promotions/${params.id}`, queryKey: [`promotion-${params.id ?? ''}`] })

  useEffect(() => {
    if ((promotionData != null) && !('error' in promotionData)) {
      setValue('holdingPageDate', new Date(promotionData.holdingPageDate))
      setValue('promoLiveDate', new Date(promotionData.promoLiveDate))
      setValue('promoEndDate', new Date(promotionData.promoEndDate))
      setValue('closedPageEndDate', new Date(promotionData.closedPageEndDate))
      setValue('personalDataDeletedBy', new Date(promotionData.personalDataDeletedBy))
      setValue('title', promotionData.title)
      setValue('languageId', promotionData.language.id.toString())
      setValue('marketId', selectedMarketId)
      setValue('url', promotionData.url)

      if (!searchParams.get('marketId')) {
        setSearchParams(prev => ({ ...readQueryParams(prev), marketId: promotionData.market.id.toString() }), { replace: true })
      }
    }
  }, [promotionData])

  useEffect(() => {
    if (selectedMarketId !== undefined && watch('marketId') !== selectedMarketId) {
      setValue('marketId', selectedMarketId)
    }
  }, [selectedMarketId])

  if ((promotionData != null) && !('error' in promotionData)) {
    return (
      <Box>
        <Provider>
          <form onSubmit={onHandleSubmit}>
            <Card sx={{ p: 4 }}>
              <Input control={control} label='Title' name='title' type='text' />

              <Box display='flex' flexWrap='wrap' gap={4}>
                <Box flexBasis='300px' flexGrow={1}><Input control={control} label='holdingPageDate' name='holdingPageDate' type='datetime' /></Box>
                <Box flexBasis='300px' flexGrow={1}><Input control={control} label='promoLiveDate' name='promoLiveDate' type='datetime' /></Box>
              </Box>
              <Box display='flex' flexWrap='wrap' gap={4}>
                <Box flexBasis='300px' flexGrow={1}><Input control={control} label='promoEndDate' name='promoEndDate' type='datetime' /></Box>
                <Box flexBasis='300px' flexGrow={1}><Input control={control} label='closedPageEndDate' name='closedPageEndDate' type='datetime' /></Box>
              </Box>
              <Box display='flex' flexWrap='wrap' gap={4}>
                <Box flexBasis='300px' flexGrow={1}><Input control={control} label='personalDataDeletedBy' name='personalDataDeletedBy' type='datetime' /></Box>
              </Box>

              <Box display='flex' flexWrap='wrap' gap={4}>
                <Box flexBasis='300px' flexGrow={1}>
                  <Select control={control} renderFunction={(el) => <MenuItem value={el.id}>{el.name}</MenuItem>} elements={market?.languages ?? []} label='Language' name='languageId' />
                </Box>
                <Box flexBasis='300px' flexGrow={1}><Input control={control} label='Url' name='url' type='text' /></Box>
              </Box>

              <Box display='flex' flexWrap='wrap' gap={4} alignItems='center'>
                <Box flexBasis='300px' flexGrow={1}>
                  <FileUpload control={control} extensions={imageExt} label='Banner' name='file' />
                </Box>
                <Box flexBasis='300px' flexGrow={1}>
                  <img src={`${process.env.REACT_APP_API_URL}/promotions/${promotionData.fileId.name}.${promotionData.fileId.extension}`} width={300} height={200} style={{ objectFit: 'contain' }} />
                </Box>
              </Box>
            </Card>
            <Box mt={2} display='flex' justifyContent='flex-end'>
              <Button type='submit' variant='contained' color='success'>
                {t('common.save')}
              </Button>
            </Box>
          </form>
        </Provider>
      </Box>
    )
  } else {
    return <>{null}</>
  }
}

export default EditPromotion

const useEditPromotion = (id?: string, selectedMarketId?: string): UseHandleFormReturnType<any> => {
  const { t } = useTranslation()
  const { token, refreshToken } = useAuthContext()
  const { changeMessage } = useAlert()
  const queryClient = useQueryClient()

  const { mutate, data: updateResponse } = useMutation(
    async (promotionData: FormData) => await axiosPatch<{ generatedMaps: [], raw: [], affected: number }>(token, refreshToken, `promotions/edit/${promotionData.get('id')}`, promotionData))

  useEffect(() => {
    if ((updateResponse != null) && updateResponse instanceof Object && 'error' in updateResponse) {
      changeMessage(t('common.failure'), 'error', () => { })
    }
    if ((updateResponse != null) && updateResponse instanceof Object && !('error' in updateResponse) && 'affected' in updateResponse) {
      changeMessage(t('common.success'), 'success', () => { })
      void queryClient.refetchQueries([`promotion-${id ?? ''}`])
    }
  }, [updateResponse])

  return useHandleForm({
    defaultValues: {
      id,
      holdingPageDate: new Date(),
      promoLiveDate: new Date(),
      promoEndDate: new Date(),
      closedPageEndDate: new Date(),
      personalDataDeletedBy: new Date(),
      marketId: selectedMarketId,
      languageId: '',
      url: ''
    },
    validate: Yup => Yup.object().shape({
      id: Yup.string().required(),
      title: Yup.string().required(t('forms.required', { field: t('promotion.title') }) ?? 'Required'),
      file: Yup.mixed(),
      holdingPageDate: Yup.date().required(t('forms.required', { field: t('promotion.from') }) ?? 'Required'),
      promoLiveDate: Yup.date().required(t('forms.required', { field: t('promotion.to') }) ?? 'Required'),
      promoEndDate: Yup.date().required(t('forms.required', { field: t('promotion.to') }) ?? 'Required'),
      closedPageEndDate: Yup.date().required(t('forms.required', { field: t('promotion.to') }) ?? 'Required'),
      personalDataDeletedBy: Yup.date().required(t('forms.required', { field: t('promotion.to') }) ?? 'Required'),
      marketId: Yup.string().required(t('forms.required', { field: t('promotion.marketName') }) ?? 'Required'),
      languageId: Yup.string().required(t('forms.required', { field: t('promotion.languageName') }) ?? 'Required'),
      url: Yup.string().required(t('forms.required', { field: t('promotion.url') }) ?? 'Required')
        .test({
          test: (value) => {
            const urlRegex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/

            return urlRegex.test(value)
          },
          message: t('forms.badUrlFormat') ?? 'Bad url format',
          name: 'urlFormat'
        })
    }),
    onValidationSubmit: (data) => {
      console.log(data)
      const fd = new FormData()
      Object.entries(data).forEach(([fieldKey, fieldValue]) => {
        if (fieldValue instanceof File) {
          fd.append('file', fieldValue)
        } else if (fieldValue instanceof Date) {
          const formatString = 'yyyy-MM-dd HH:mm:ss'
          fd.append(fieldKey, format(fieldValue, formatString))
        } else {
          fd.append(fieldKey, fieldValue.toString())
        }
      })
      mutate(fd)
    }
  })
}
